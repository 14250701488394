import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';

const BulkReminderModal: FC<any> = ({ openModal, handleClose }) => {
  const initialValues = { medium: null, note: '' };

  return (
    <CommonModal
      submitText="continue"
      isOpen={!!openModal}
      handleClose={handleClose}
      title="Send Bulk Reminders for Deal: Getsupp"
      hideButtons
    >
      <FormContainer>
        <Formik initialValues={initialValues} onSubmit={(values: any) => console.log(values)}>
          {(form) => (
            <Stack gap={2}>
              <RadioButtonSelect
                fieldName="medium"
                id="medium"
                formLabel="Send Invite via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email' },
                  { name: 'Whatsapp', value: 'whatsapp' }
                ]}
              />
              <InputField
                fieldName="note"
                id="note"
                label="Add a personalised note to the invite email/whatsapp"
                multiline
                minRows={5}
              />

              <br />
              <Stack direction="row" gap={1} justifyContent="end">
                <ActionButton onClick={form.handleSubmit}>Send Test Email/Whatsapp</ActionButton>
                <ActionButton>Send Invite Email/WhatsApp</ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default BulkReminderModal;
