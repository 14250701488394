import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { DealStatusEnum } from '../constants/DealsConstant';
import TableLogo from '../../common/components/TableLogo';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import DealsManager from '../manager/DealsManager';
import { toast } from 'react-toastify';
import { validateArray } from '../../../common/utils/common';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';

const Deals = () => {
  const authContext = useContext(AuthContext);
  const backersManager = new DealsManager(authContext);
  const navigate = useNavigate();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const getDealsData = async () => {
    try {
      setLoading(true);
      const result = await backersManager.getAllDeals();
      if (result?.all_count > 0) {
        setData(result);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDealsData();
  }, []);

  const [filters, setFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string>(DealStatusEnum.ALL);

  const navigateToLaunch = () => {
    navigate('/deals/create');
  };

  const breadcrumbData = {
    breadcrumbSeparator: false,
    stepKey: 1,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Deals',
      navigationButton: false,
      helpText: 'Deals'
    }
  };

  const columnsData = [
    {
      field: 'logo',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 50,
      sortable: false,

      renderCell: (params: any) => <TableLogo id={params?.row?.id} url={params?.row?.logo} />
    },
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'STARTUP NAME',
      isSearchable: true,
      renderCell: (params: any) => (
        <LinkButton
          title={params?.row?.name}
          onClick={() => navigate(`/deals/${params?.row?.id}`, { state: params?.row })}
        />
      )
    },
    {
      field: 'deal_status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'DEAL STATUS',
      type: 'date'
    },
    {
      field: 'launched_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'LAUNCHED DATE',
      type: 'date'
    },
    {
      field: 'funding_ask',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'FUNDING ASK',
      type: 'number'
    },
    {
      field: 'commited',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'COMMITED',
      type: 'number'
    },
    {
      field: 'transfer_to_startup',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'TRANSFER TO STARTUP'
    },
    {
      field: 'transfer_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'TRANSFER DATE'
    }
  ];

  const filterDataList = (fltr: string) => {
    switch (fltr) {
      case DealStatusEnum.ALL:
        return data?.all_startups ?? [];
      case DealStatusEnum.LIVE:
        return data?.ongoing_startups ?? [];
      case DealStatusEnum.DRAFT:
        return data?.saved_startups ?? [];
      case DealStatusEnum.CANCELLED:
        return data?.canceled_startups ?? [];
      case DealStatusEnum.CLOSED:
        return data?.closed_startups ?? [];
      default:
        return data?.all_startups ?? [];
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <ActionButton onClick={navigateToLaunch}>Launch Deal</ActionButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>
        <FilterChip
          label="All"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.ALL}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.LIVE);
          }}
        />
        <FilterChip
          label="Live"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.LIVE}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.LIVE);
          }}
        />
        <FilterChip
          label="Draft"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.DRAFT}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.DRAFT);
          }}
        />
        <FilterChip
          label="Closed"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.CLOSED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.CLOSED);
          }}
        />
        <FilterChip
          label="Funded"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.FUNDED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.FUNDED);
          }}
        />
        <FilterChip
          label="Cancelled"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.CANCELLED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.CANCELLED);
          }}
        />
      </Stack>
      <LvComplexTable
        rows={filterDataList(selectedStatus)}
        columns={columnsData}
        leftPinnedColumns={['logo', 'name']}
        activeFilters={filters}
        // filterMode="server"
        // checkboxSelection
        // onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        // enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />
    </Box>
  );
};

export default Deals;
