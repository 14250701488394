import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { DropdownButton } from '../../../ui-library/components/buttons/dropdown-button';
import { ActionOptions, ActionOptionsList } from '../constants/IndividualDealConstant';
import DraftDeal from './DraftDeal';
import ShareDealModal from '../modals/ShareDealModal';
import { AuthContext } from '../../../contexts/authContext';
import DealsManager from '../manager/DealsManager';
import { toast } from 'react-toastify';
import StartupResponse from './StartupResponse';

const IndividualDeal = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const dealsManager = new DealsManager(authContext);
  const { id } = useParams();
  const { state } = useLocation();

  const [showShareDealModal, setSSDM] = useState(false);

  const [schemeData, setSchemeData] = useState<any>(null);
  const [startupData, setStartupData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getBackersData = async () => {
    try {
      setLoading(true);
      const result = await dealsManager.getDealDetail(id);

      if (result?.scheme_data) {
        setSchemeData(result?.scheme_data);
      }
      if (result?.startups_response) {
        setStartupData(result?.startups_response);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBackersData();
  }, []);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: state?.name,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: state?.name,
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const handleActionClick = (value: string) => {
    switch (value) {
      case ActionOptions.SHARE_DEAL_URL:
        setSSDM(true);
        return;
      case 'default':
        return;
    }
  };

  return (
    <Stack sx={{ overflowX: 'hidden' }} gap={2}>
      {showShareDealModal && <ShareDealModal openModal={showShareDealModal} handleClose={() => setSSDM(false)} />}
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <DropdownButton options={ActionOptionsList} onOptionClick={handleActionClick} buttonText="Actions" />
        </Box>
      </Stack>
      <DraftDeal />
      <StartupResponse startupData={startupData} />
    </Stack>
  );
};

export default IndividualDeal;
