import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import DealsService from '../service/DealsService';

export default class DealsManager {
  private userId: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
  }

  async getAllDeals() {
    try {
      const response: any = await DealsService.getAllDeals(this.userId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getDealDetail(dealId?: string) {
    try {
      const response: any = await DealsService.getDealDetail(this.userId, dealId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
