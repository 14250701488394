export type ActionOptionType = {
  label: string;
  disabled?: boolean;
  subText?: string;
  subTextStyle?: any;
  labelStyle?: any;
};

export enum ActionOptions {
  EDIT_DETAILS = 'Edit Details',
  SEND_REINVITE_EMAIL = 'Send Re-Invite Email',
  SEND_REINVITE_WHATSAPP = 'Re-invite on WhatsApp',
  BLOCK_FROM_SYNDICATE = 'Block from Syndicate'
}

export const ActionOptionsList: ActionOptionType[] = [
  {
    label: ActionOptions.EDIT_DETAILS
  },
  {
    label: ActionOptions.SEND_REINVITE_EMAIL
  },
  {
    label: ActionOptions.SEND_REINVITE_WHATSAPP
  },
  {
    label: ActionOptions.BLOCK_FROM_SYNDICATE
  }
];
