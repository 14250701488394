import React from 'react';
import { Grid, Box, Stack } from '@mui/material';
import { OptionWithEmployeesStatsticCard } from '../../../ui-library/components/card/option-with-employees-statstic-card';
import { useNavigate } from 'react-router-dom';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import DraftDeal from '../../deals/components/DraftDeal';
import LiveDeal from '../../deals/components/LiveDeal';

function Dashboard() {
  const navigate = useNavigate();
  const navigateToLaunch = () => {
    navigate('/deals/create');
  };
  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard title="PORTFOLIO COMPANIES" value="12" width="250px" height="94px" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard title="TOTAL INVESTED" value="$ 25 CR" height="94px" width="250px" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title="BACKERS"
            value="57"
            height="94px"
            width="250px"
            onClick={() => navigate('/backers')}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <Stack direction="row" justifyContent="space-between" mb={1} alignItems="center">
          <SectionHeader>Live Deals</SectionHeader>

          <ActionButton onClick={navigateToLaunch}>Launch Deal</ActionButton>
        </Stack>
        <LiveDeal dealData={{ name: 'Deal Name' }} />
      </Box>
      <Box mt={3}>
        <Stack direction="row" justifyContent="space-between" mb={1} alignItems="center">
          <SectionHeader>Draft Deals</SectionHeader>
        </Stack>
        <DraftDeal />
      </Box>
    </Box>
  );
}

export default Dashboard;
