import { Box } from '@mui/material';
import React from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';

const SettingCard = () => {
  const cardOptions = [
    {
      label: 'Syndicate Name',
      spacing: 3,
      value: 'Dexter Angels'
    },
    {
      label: 'Joined on LetsVenture',
      spacing: 3,
      value: 'May 1, 2020'
    },
    {
      label: 'Primary Admin',
      spacing: 3,
      value: 'Mohit Satyanand'
    },
    {
      label: 'cc emails',
      spacing: 3,
      renderer: <></>
    },
    {
      label: 'Allow backers to access public Deals',
      spacing: 3,
      value: 'NO',
      helpText: 'helptext'
    }
  ];

  return (
    <Box className="card_border text-left" mb={3}>
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default SettingCard;
