import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../../../ui-library/components/navs/breadcrumb';
import { HorizontalTabs } from '../../../../ui-library/components/tabs/horizontal-tabs';
import { DEAL_FORM_TAB_OPTIONS, DealTabType } from '../../constants/IndividualDealConstant';
import StartupInfoForm from './StartupInfoForm';
import SchemeInfoForm from './SchemeInfoForm';

const DealForm = () => {
  const navigate = useNavigate();

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: 'Launch Deal',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Launch Deal',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const [selectedTab, setSelectedTab] = useState(DealTabType.STARTUP_INFO);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Breadcrumb
        steps={breadcrumbData.steps}
        breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
        pageHeader={breadcrumbData.pageHeader}
        stepKey={breadcrumbData.stepKey}
      />
      <Box width="40%">
        <HorizontalTabs
          tabs={DEAL_FORM_TAB_OPTIONS}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => setSelectedTab(tab)}
        />
      </Box>

      {selectedTab === DealTabType.STARTUP_INFO && <StartupInfoForm />}
      {selectedTab === DealTabType.SCHEME_INFO && <SchemeInfoForm />}
    </Box>
  );
};

export default DealForm;
