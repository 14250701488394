import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { Link, useNavigate } from 'react-router-dom';
import ActionContainer from '../../common/components/ActionContainer';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import BulkReminderModal from '../modals/BulkReminderModal';
import ManageCommitModal from '../modals/ManageCommitModal';
import LiveDeal from './LiveDeal';

const StartupResponse: FC<any> = ({ startupData }) => {
  const [filters, setFilters] = useState([]);
  const [showManageCommitModal, setSMCM] = useState(false);
  const [showBulkReminderModal, setSBRM] = useState(false);

  const navigate = useNavigate();

  const handleViewCommunicationLogs = () => {
    navigate(`/deals/${startupData?.id}/communication-logs`);
  };
  const handleInviteBackersToDeal = () => {
    navigate(`/deals/${startupData?.id}/invite-backers`);
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      isSearchable: true,
      renderCell: (params: any) => <Link to={`/backers/${params?.row?.name}`}>{params?.row?.name}</Link>
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'EMAIL',
      isSearchable: true
    },
    {
      field: 'invited_to_deal',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'INVITED TO DEAL'
    },
    {
      field: 'commitment',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'COMMITMENT'
    },
    {
      field: 'commited',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'COMMITED',
      type: 'date'
    },
    {
      field: 'commit_date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'COMMIT DATE',
      type: 'date'
    },
    {
      field: 'drawdown_sent',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'DRAWDOWN SENT'
    },
    {
      field: 'transfer_to_aif',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 100,
      type: 'number',
      headerName: 'TRANSFER TO AIF'
    },

    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      renderCell: (params: any) => {
        return (
          <ActionContainer>
            <LinkButton onClick={() => {}} title="block" />
            <LinkButton
              onClick={() => {
                setSMCM(params.row);
              }}
              title="manage commit"
            />
          </ActionContainer>
        );
      }
    }
  ];

  const rowData = [
    {
      id: 1,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    },
    {
      id: 2,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    },
    {
      id: 3,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    }
  ];

  return (
    <>
      <BulkReminderModal
        openModal={showBulkReminderModal}
        handleClose={() => {
          setSBRM(false);
        }}
      />
      <ManageCommitModal
        openModal={showManageCommitModal}
        handleClose={() => {
          setSMCM(false);
        }}
      />

      <LiveDeal dealData={startupData} />

      <Stack direction="row" justifyContent="space-between" mb={2}>
        <SectionHeader customStyles={{ textAlign: 'start' }}>Backers for this Deal</SectionHeader>
        <Box gap={1} display="flex">
          <ActionButton onClick={handleViewCommunicationLogs}>View Communications Log</ActionButton>
          <ActionButton
            onClick={() => {
              // setSBRM(true);
            }}
          >
            Send Bulk Reminders
          </ActionButton>
          <ActionButton onClick={handleInviteBackersToDeal}>Invite Backers to this Deal</ActionButton>
        </Box>
      </Stack>

      <LvComplexTable
        rows={rowData}
        columns={columnsData}
        leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
        activeFilters={filters}
        // filterMode="server"
        checkboxSelection
        onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        // enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />
    </>
  );
};

export default StartupResponse;
