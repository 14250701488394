import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-pro';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ActionContainer from '../../common/components/ActionContainer';
import InviteBackerModal from '../modals/InviteBackerModal';
import BulkInviteBackerModal from '../modals/BulkInviteBackerModal';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../manager/BackersManager';
import { toast } from 'react-toastify';
import {
  BACKER_ONBOARDING_STATUS,
  BACKER_ONBOARDING_STATUS_KEY,
  ColumnObjectType,
  TYPE_OF_COMMUNICATION_KEY,
  backersColumnsData,
  breadcrumbData
} from '../constants/BackersConstant';
import { useNavigate } from 'react-router-dom';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';

function Backers() {
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);
  const navigate = useNavigate();

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const getBackersData = async () => {
    try {
      setLoading(true);
      const result = await backersManager.getAllBackers();
      if (result) {
        setData(result);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBackersData();
  }, []);

  const [filters, setFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(BACKER_ONBOARDING_STATUS.ALL);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openInviteBackerModal, setOIBM] = useState<any>(false);
  const [openBulkInviteBackerModal, setOBIBM] = useState(false);
  const [reInvite, setReInvite] = useState(false);

  const setReInviteFalse = () => {
    setTimeout(() => setReInvite(false), 500);
  };
  const handleCloseInvite = () => {
    setOIBM(false);
    setReInviteFalse();
  };
  const handleCloseBulkInvite = () => {
    setOBIBM(false);
    setReInviteFalse();
  };

  const handleSubmitInvite = async (values: any) => {
    try {
      const response = await backersManager.inviteMember(values);
      toast.success(response?.message);
      handleCloseInvite();
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleSubmitReInvite = async (values: any) => {
    try {
      const response = await backersManager.reInviteMember(
        selectedRows,
        openInviteBackerModal?.inv_id,
        values?.medium,
        TYPE_OF_COMMUNICATION_KEY[openInviteBackerModal?.profile_status],
        openInviteBackerModal?.type_of_investment_profile
      );
      toast.success(response?.message);
      handleCloseInvite();
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleSubmitBulkInvite = async (values: any) => {
    try {
      const response = await backersManager.inviteMember(values);
      toast.success(response?.message);
      handleCloseBulkInvite();
    } catch (e: any) {
      toast.error(e);
    }
  };

  let backersColumns = backersColumnsData?.map((item: ColumnObjectType) => {
    switch (item?.field) {
      case 'name':
        item.renderCell = (params: any) =>
          params?.row?.profile_status === BACKER_ONBOARDING_STATUS.CA_ESIGNED ? (
            <LinkButton
              title={params?.row?.name}
              onClick={() => navigate(`/backers/${params?.row?.inv_id}`, { state: params?.row })}
            />
          ) : (
            <p>{params?.row?.name}</p>
          );
        return item;

      case 'actions':
        item.renderCell = (params: any) => {
          return (
            <ActionContainer>
              {(params?.row?.profile_status === BACKER_ONBOARDING_STATUS.INVITED ||
                params?.row?.profile_status === BACKER_ONBOARDING_STATUS.CA_SENT) && (
                <LinkButton
                  onClick={() => {
                    setReInvite(true);
                    setOIBM(params.row);
                  }}
                  title="re-invite"
                ></LinkButton>
              )}
              <LinkButton onClick={() => {}} title="block"></LinkButton>
            </ActionContainer>
          );
        };
        return item;
      default:
        return item;
    }
  });

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <InviteBackerModal
        openModal={openInviteBackerModal}
        reInvite={reInvite}
        handleClose={handleCloseInvite}
        handleSubmit={reInvite ? handleSubmitReInvite : handleSubmitInvite}
      />
      <BulkInviteBackerModal
        openModal={openBulkInviteBackerModal}
        reInvite={reInvite}
        selectedRows={selectedRows}
        handleClose={handleCloseBulkInvite}
        handleSubmit={handleSubmitBulkInvite}
      />
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <ActionButton
            onClick={() => {
              setReInvite(true);
              setOBIBM(true);
            }}
            disabled={!selectedRows?.length}
          >
            Bulk Re-Invite Backers
          </ActionButton>
          <ActionButton onClick={() => setOBIBM(true)}>Bulk Invite Backers</ActionButton>
          <ActionButton onClick={() => setOIBM(true)}>Invite Backer to Syndicate</ActionButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.ALL}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.ALL}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.ALL);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.INVITED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.INVITED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.INVITED);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.CA_SENT}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.CA_SENT}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.CA_SENT);
          }}
        />
        <FilterChip
          label={BACKER_ONBOARDING_STATUS.CA_ESIGNED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.CA_ESIGNED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.CA_ESIGNED);
          }}
        />

        <FilterChip
          label={BACKER_ONBOARDING_STATUS.PROFILE_CREATED}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === BACKER_ONBOARDING_STATUS.PROFILE_CREATED}
          onClick={() => {
            setSelectedStatus(BACKER_ONBOARDING_STATUS.PROFILE_CREATED);
          }}
        />
      </Stack>
      {loading ? (
        <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          // rows={rowData}
          rows={data[BACKER_ONBOARDING_STATUS_KEY[selectedStatus]]}
          columns={backersColumns}
          leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
          activeFilters={filters}
          // filterMode="server"
          checkboxSelection
          onselectionChange={(selectedRows: any) => setSelectedRows(selectedRows)}
          // enableAdvanceNumberFiltering
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}
    </Box>
  );
}

export default Backers;
