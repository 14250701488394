import React, { FC } from 'react';
import { InputField } from '../../../../ui-library/components/form-elements/CustomTextField';
import { CheckBoxSelect } from '../../../../ui-library/components/form-elements/CheckBoxSelect';
import { Dropdown } from '../../../../ui-library/components/dropdowns/core-dropdown';
import { FileUpload } from '../../../../ui-library/components/form-elements/file-upload/FileUpload';
import { RadioButtonSelect } from '../../../../ui-library/components/form-elements/RadioButtonSelect';
import { Stack } from '@mui/material';
import { Formik } from 'formik';
import { ActionButton } from '../../../../ui-library/components/buttons/action-button';

const SchemeInfoForm: FC<any> = () => {
  const initialValues = {};

  return (
    <Formik initialValues={initialValues} onSubmit={(values: any) => console.log(values)}>
      {(form) => (
        <Stack gap={1} width={'60%'} textAlign="left">
          <InputField fieldName="syndicate_name" id="syndicate_name" label="Name of Syndicate" redquired />
          <InputField
            fieldName="entity_name"
            id="entity_name"
            label="Startup Entity Name as per Govt Records"
            redquired
          />
          <InputField
            fieldName="bussiness_description"
            id="bussiness_description"
            label="What is the business the Startup is engaged in (1 line description)?"
            redquired
            multiline
            minRows={5}
          />
          <InputField
            fieldName="pre_money_valuation"
            id="pre_money_valuation"
            label="What is the Pre-Money Valuation?"
            redquired
          />
          <InputField
            fieldName="total_round_size"
            id="total_round_size"
            label="What is the total Round Size (LV + External)?"
            redquired
          />
          <InputField
            fieldName="lv_round_size"
            id="lv_round_size"
            label="What is the approx Round size on LV (via this scheme)?"
            redquired
          />
          <CheckBoxSelect
            fieldName="is_primary_transaction"
            id="is_primary_transaction"
            label="I confirm that this is a Primary Transaction (shares issued directly by company)."
            helperText="Note: LV AIF is not allowed to do secondary transaction (shares are purchased from shareholders) as per SEBI regulation."
          />
          <Dropdown
            fieldName="security_typr"
            id="security_typr"
            options={[]}
            label="What is the Type of Security being issued?"
            required
          />
          <InputField
            fieldName="price_per_share"
            id="price_per_share"
            label="What is the price per share/security?"
            redquired
          />
          <InputField
            fieldName="share_lv_angel_fund"
            id="share_lv_angel_fund"
            label="What is the approx # of shares/securities being issued to LV Angel Fund in this scheme?"
            disabled
          />
          <InputField
            fieldName="conversion_terms"
            id="conversion_terms"
            label="What are the Conversion Terms (for CCD or Convertible Note)?"
            multiline
            minRows={5}
          />
          <InputField
            fieldName="platform_fee"
            id="platform_fee"
            label="What is LV Platform Fee %?"
            redquired
            helperText="Note: 2% + GST is LV’s standard Platform Fee. If you specify a di!erent number,
  please mention why in the comments box at the bottom of this form."
          />

          <InputField
            fieldName="lv_carry"
            id="lv_carry"
            label="What is LV Carry %?"
            redquired
            helperText="Note: 2.5% is LV’s standard Carry. If you specify a di!erent number, please mention
    why in the comments box at the bottom of this form."
          />
          <InputField fieldName="lead_carry" id="lead_carry" label="What is Lead Carry %?" redquired />
          <InputField fieldName="hurdle_rate" id="hurdle_rate" label="What is Hurdle Rate % (optional)?" />
          <InputField
            fieldName="days_to_commit"
            id="days_to_commit"
            label="What is number of days to commit once the deal has been published?"
            required
            helperText="Note: this is enforced on the platform and your backers won't be able to commit to the deal beyond these many days."
          />
          <InputField
            fieldName="consent_last_date"
            id="consent_last_date"
            label="What is the last date for Investors to consent to the Scheme Doc?"
            required
            helperText="Note: this is not enforced on the platform. It is for documentation purpose only."
          />
          <FileUpload
            title="Upload Draft SHA"
            selectedFile={(file: any) => form.setFieldValue('file', file)}
            value={form?.values?.file}
            label="Upload Draft SHA"
            helperText="Note: Please upload the executed version of the document if available. If not, please
    upload a draft copy of the agreement for us to verify the round terms."
          />
          <RadioButtonSelect
            fieldName="pay_immediately"
            id="pay_immediately"
            required
            formLabel="Do you want the investor to pay the investment amount immediately after commitment?"
            options={[
              { name: 'Yes', value: 'yes' },
              { name: 'No', value: 'no' }
            ]}
          />
          <RadioButtonSelect
            fieldName="recognized_by_dpiit"
            id="recognized_by_dpiit"
            required
            formLabel="Is the Startup recognized by DPIIT?"
            options={[
              { name: 'Yes', value: 'yes' },
              { name: 'No', value: 'no' }
            ]}
          />
          <InputField
            fieldName="rights"
            id="rights"
            label="Rights still under Negotiation for this Scheme"
            multiline
            minRows={5}
          />
          <InputField fieldName="notes" id="notes" label="Add any Comments/Notes for LV" multiline minRows={5} />
          <br />
          <Stack direction="row" gap={2} justifyContent="end">
            <ActionButton>Save as Draft</ActionButton>
            <ActionButton>Submit to LV for Review</ActionButton>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

export default SchemeInfoForm;
