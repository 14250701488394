import React, { FC } from 'react';
import { InputField } from '../../../../ui-library/components/form-elements/CustomTextField';
import { CheckBoxSelect } from '../../../../ui-library/components/form-elements/CheckBoxSelect';
import { Dropdown } from '../../../../ui-library/components/dropdowns/core-dropdown';
import { FileUpload } from '../../../../ui-library/components/form-elements/file-upload/FileUpload';
import { RadioButtonSelect } from '../../../../ui-library/components/form-elements/RadioButtonSelect';
import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { ActionButton } from '../../../../ui-library/components/buttons/action-button';

const StartupInfoForm: FC<any> = () => {
  const initialValues = {
    startup_name: '',
    entity_name: '',
    startup_registered_india: null,
    startup_location_tokens: null,
    startup_stage: null,
    sector_tokens: null,
    bussiness_type: null,
    startup_less_10_year: null,
    startup_less_1000: null,
    startup_profile_image: [],
    startup_banner_image: [],
    summary: '',
    product_summary: '',
    startup_pitch_deck: [],
    investment_thesis: [],
    lead_investor_thesis: [],
    min_investment: ''
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values: any) => console.log(values)}>
      {(form) => (
        <Stack gap={1} width={'60%'} textAlign="left">
          <InputField
            label="Enter Startup Name"
            required
            fieldName="startup_name"
            id="startup_name"
            helperText="Brand Name e.g. PayTM"
          />
          <InputField
            label="What is the entity name as per govt records?"
            required
            fieldName="entity_name"
            id="entity_name"
            helperText="e.g. name as per ROC records in India - One97 Communications Pvt Ltd"
          />
          <Dropdown
            label="What is the country of Registration?"
            required
            fieldName="startup_registered_india"
            id="startup_registered_india"
            options={[]}
          />
          <Dropdown
            label="Which city the Startup is operating from?"
            required
            fieldName="startup_location_tokens"
            id="startup_location_tokens"
            options={[]}
          />
          <Dropdown
            label="Which stage is the startup currently in?"
            required
            fieldName="startup_stage"
            id="startup_stage"
            options={[]}
          />
          <Dropdown
            label="Which sectors the Startup operates in (select upto 3 sectors)?"
            required
            fieldName="sector_tokens"
            id="sector_tokens"
            name="sector_tokens"
            multiple
            options={[
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 }
            ]}
          />
          <Dropdown
            label="What is the type of business?"
            required
            fieldName="bussiness_type"
            id="bussiness_type"
            options={[]}
          />
          <RadioButtonSelect
            fieldName="startup_less_10_year"
            id="startup_less_10_year"
            required
            formLabel="Is the Startup less than 10 years old?"
            options={[
              { name: 'Yes', value: 'yes' },
              { name: 'No', value: 'no' }
            ]}
            helperText="Note: as per SEBI regulations, LV AIF can only invest in a startup which has been incorporated less than 10 years ago."
          />
          <RadioButtonSelect
            fieldName="startup_less_1000"
            id="startup_less_1000"
            required
            formLabel="Is the Startup revenue less than ₹ 100 Cr in any 12 months in its history?"
            options={[
              { name: 'Yes', value: 'yes' },
              { name: 'No', value: 'no' }
            ]}
            helperText="Note: as per SEBI regulations, LV AIF can only invest in a startup whose turnover doesn't exceed ₹ 100 Cr in any of the
            financial years since its incorporation."
          />
          <FileUpload
            title="Upload Startup Logo"
            selectedFile={(file: any) => form.setFieldValue('startup_profile_image', file)}
            value={form?.values?.startup_profile_image}
            label="Upload Startup Logo"
            helperText="Upload the logo of in jpg or png format. Square logo with 400x400 px recommended."
          />
          <FileUpload
            title="Upload Startup Banner Image"
            selectedFile={(file: any) => form.setFieldValue('startup_banner_image', file)}
            value={form?.values?.startup_banner_image}
            label="Upload Startup Banner Image"
            helperText="Banner image makes the startup profile look good to investors.
            Upload image in JPG or PNG format, seize of 1200x800 px recommended"
          />
          <InputField
            fieldName="summary"
            id="summary"
            label="Enter the Elevator Pitch for the Startup"
            multiline
            minRows={5}
          />
          <InputField
            fieldName="product_summary"
            id="product_summary"
            label="Enter the Startup Product/Service Summary"
            multiline
            minRows={5}
          />
          <FileUpload
            title="Upload Startup Pitch Deck"
            selectedFile={(file: any) => form.setFieldValue('startup_pitch_deck', file)}
            value={form?.values?.startup_pitch_deck}
            label="Upload Startup Pitch Deck"
            helperText="Upload the pdf file (max size 20 MB)"
          />
          <InputField
            fieldName="investment_thesis"
            id="investment_thesis"
            label="Why are you (syndicate lead) investing in this Startup?"
            multiline
            minRows={5}
          />
          <FileUpload
            title="Upload your (syndicate lead) thesis document"
            selectedFile={(file: any) => form.setFieldValue('lead_investor_thesis', file)}
            value={form?.values?.lead_investor_thesis}
            label="Upload your (syndicate lead) thesis document"
            helperText="Upload the pdf file (max size 20 MB)"
          />
          <InputField
            label="What is the minimum commitment per investor?"
            required
            fieldName="min_investment"
            id="min_investment"
            helperText="Note: this is the default value for the deal. Once you launch the deal, you can always
            modify it on a per investor basis."
          />
          <br />
          <Stack direction="row" gap={2} justifyContent="end">
            <ActionButton>Save as Draft</ActionButton>
            <ActionButton onClick={form?.handleSubmit}>Next</ActionButton>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

export default StartupInfoForm;
