import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { getColor } from '../../colors';
import { Field } from 'formik';

export type CheckBoxSelectProps = {
  /**
   * here you can pass form Label
   *
   */

  formLabel?: any;

  /**
   *onChange function
   *
   */
  label?: string;

  onChange?: any;
  disabled?: boolean;

  /**
   *value of RadioGroup
   *
   */

  value?: any;

  /**unique identifier for input field */

  id?: string;

  /**unique name for input field */
  name?: string;

  /**implicit prop from formik. Dont have to pass as prop */
  form?: any;

  error?: boolean;

  index?: number;

  fieldArray?: boolean;

  formName?: string;

  fieldName?: string;
  helperText?: string;
};

const CustomCheckBox = ({
  formLabel,
  label,
  onChange,
  value,
  disabled,
  name,
  id = '',
  form,
  error,
  fieldArray = false,
  fieldName = '',
  index = 0,
  formName = '',
  helperText
}: CheckBoxSelectProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      form?.setFieldValue(id, event.target.value);
    }
    onChange && onChange(event.target.value);
  };

  const getErrorMessage = () => {
    let errorMessage = '';
    if (form) {
      if (fieldArray && error && form?.touched?.[formName]?.[index]?.[fieldName]) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      }
    }
    return errorMessage;
  };
  return (
    <FormControl sx={{ textAlign: 'left' }}>
      <FormLabel>{formLabel ?? ''}</FormLabel>
      <FormControlLabel
        onChange={handleChange as any}
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        label={
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              color: getColor('border', 200)
            }}
          >
            {label}
          </span>
        }
        sx={{
          color: getColor('border', 200),
          fontWeight: 400,
          fontFamily: 'Work Sans',
          paddingRight: '47px'
        }}
        control={<Checkbox />}
      />
      <FormHelperText error={true}>{getErrorMessage()}</FormHelperText>
      {helperText && (
        <Typography variant="caption_regular" pl="2rem">
          <span style={{ color: getColor('neutral', 600) }}>{helperText}</span>
        </Typography>
      )}
    </FormControl>
  );
};

export default CustomCheckBox;

export function CheckBoxSelect(props: any) {
  return <Field component={CustomCheckBox} {...props} />;
}
