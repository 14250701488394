import React, { CSSProperties, useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import { Typography, TextField } from '@mui/material';
import { NumericFormat, removeNumericFormat } from 'react-number-format';
import { getColor } from '../../colors';

const useStyles = makeStyles({
  red_border: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      '& fieldset': {
        border: `1px solid ${getColor('error', 800)}`
      },
      '&:hover fieldset': {
        borderColor: getColor('error', 800)
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${getColor('error', 800)}`
      }
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& fieldset': {
        borderColor: `${getColor('error', 800)} !important`
      },
      '& input': {
        '-webkit-text-fill-color': getColor('border', 200)
      },
      backgroundColor: getColor('background', 50)
    }
  },
  border: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      '& fieldset': {
        border: `1px solid ${getColor('extra', 2000)}`
      },
      '&:hover fieldset': {
        borderColor: getColor('primary', 700)
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${getColor('primary', 700)}`
      }
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& fieldset': {
        borderColor: `${getColor('primary', 700)} !important`
      },
      '& input': {
        '-webkit-text-fill-color': getColor('border', 200)
      },
      backgroundColor: getColor('background', 50)
    }
  },

  root_textfield: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '14px',
      transform: 'translate(14px, 10px) scale(1)',
      color: getColor('extra', 1900),
      fontFamily: 'Work Sans'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75)',
      color: getColor('primary', 700),
      left: 0
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: getColor('primary', 700)
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColor('primary', 700)
    },
    '& .MuiFormControl-root': {
      border: `1px solid ${getColor('extra', 2000)}`
    },

    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
      fontFamily: 'Work Sans'
    },
    '& .MuiInputBase-inputMultiline': {
      padding: '0px'
    },
    '& input': {
      '&::placeholder': {
        color: `getColor('extra', 600) !important`,
        opacity: '1',
        fontWeight: 400
      }
    }
  },
  labelTransition: {
    transform: 'translate(44px, 20px) scale(1)'
  }
});

export type InputFieldProps = {
  /**
   * additional styles to be added for the table
   */
  customStyles?: CSSProperties;
  /**
   *name of the textField
   */
  label?: String;
  /**
   *width of the textField , Ex- '560px'
   */
  width?: any;
  /**
   *check mui textfield Api for sizes , it takes small ,medium
   */
  size?: any;

  /**
   *check mui textfield Api for variants , default one is outlined
   */

  variant?: any;
  /**
   *here you can trigger onChange function
   */

  onChange?: Function;
  /**
   *is this is true , it will make the field mandatory and put an asterisk on the label name.
   */
  required?: boolean;
  /**
   *here we can pass mui InputField Props for textfield component
   */
  InputProps?: any;
  /**
   *here we can pass mui InputLabel Props for textfield component
   */
  InputLabelProps?: any;

  /**
   *here we can pass helpText which appears below
   */
  helperText?: any;

  /**
   *here we can assign value to a particular field
   */

  value?: any;

  /**
   *here you can set error(boolean) as true to show error , it will turn highlightborder & labelName color to red
   */
  error?: boolean;

  /**
   *here you can pass the placeholder
   */
  placeholder?: string;

  /**unique identifier for input field */
  id?: string;

  /**unique name for input field */
  name?: string;

  /**boolean if you want to format number in input field */
  formatNumber?: boolean;

  /**currency code for formatting number in input field (lakhs or million) */
  currencyCode?: string;

  /**implicit prop from formik. Dont have to pass as prop */
  form?: any;

  autoFocus?: boolean;

  multiline?: boolean;

  type?: string;

  disabled?: boolean;

  minRows?: number | string;

  maxRows?: number | string;

  index?: number;

  /**if the field is being used inside object form */
  isObject?: string;
  /**is true, border color of the inputField will be red */
  redBorderColor?: boolean;

  fieldArray?: boolean;

  formName?: string;

  fieldName?: string;

  /** trigger function on onBlur event */
  handleBlur?: Function | null;
};

const NumberFormatCustom = forwardRef((props: any, ref: any) => {
  const { onChange, value, formatNumber, currencyCode, prefix, onBlur, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={value}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.id,
            value: values.floatValue
          }
        });
      }}
      onBlur={(e: any) => {
        const value = removeNumericFormat(
          e.target.value,
          {
            from: {
              start: 0,
              end: 0
            },
            to: {
              start: 0,
              end: e.target.value.length
            },
            lastValue: ''
          },
          {
            thousandsGroupStyle: `${currencyCode === 'INR' ? 'lakh' : 'thousand'}`,
            thousandSeparator: `${formatNumber ? ',' : ''}`
          }
        );
        onBlur({
          target: {
            name: props.id,
            value: value
          }
        });
      }}
      thousandsGroupStyle={currencyCode === 'INR' ? 'lakh' : 'thousand'}
      thousandSeparator={formatNumber ? ',' : ''}
    />
  );
});

export const CustomTextField = ({
  label = '',
  width = '100%',
  size = 'small',
  variant = 'outlined',
  onChange,
  required = false,
  customStyles = {},
  InputProps = {},
  InputLabelProps,
  isObject,
  helperText,
  value,
  error,
  placeholder,
  name,
  form,
  id = '',
  type = 'text',
  autoFocus = false,
  multiline = false,
  disabled = false,
  minRows,
  maxRows,
  fieldArray = false,
  fieldName = '',
  index = 0,
  formName = '',
  formatNumber = true,
  currencyCode = 'INR',
  redBorderColor = false,
  handleBlur = null
}: InputFieldProps) => {
  const classes = useStyles();
  const [shrink, setShrink] = useState(false);
  useEffect(() => {
    value === '' || value === undefined || value === null ? setShrink(false) : setShrink(true);
  }, [value]);

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (typeof value === 'string') value = value.trimLeft();
    onChange && onChange(value);
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      form?.setFieldValue(id, value);
    }
  };

  const getErrorMessage = () => {
    let errorMessage = '';
    if (form) {
      if (fieldArray && error && form?.touched?.[formName]?.[index]?.[fieldName]) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      } else if (isObject) {
        errorMessage = form?.errors?.[isObject]?.[fieldName];
      }
    }
    return errorMessage;
  };
  console.log(form?.errors);

  let inputProps = {};
  if (type === 'number' && !multiline) {
    if (Object.keys(InputProps).length) {
      InputProps = { inputComponent: NumberFormatCustom, ...InputProps };
    } else {
      InputProps.inputComponent = NumberFormatCustom;
    }
    inputProps = {
      formatNumber: formatNumber,
      currencyCode: currencyCode
    };
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <TextField
        className={`${classes.root_textfield} ${redBorderColor ? classes.red_border : classes.border}`}
        label={label}
        name={name}
        id={id}
        variant={variant}
        sx={{
          width,
          input: { color: getColor('border', 200) },
          '& .MuiInputLabel-outlined': { left: InputProps?.startAdornment ? '26px' : '0px' },
          '& .MuiOutlinedInput-input': {
            padding: InputProps?.startAdornment ? '10px 0px !important' : null
          },
          ...customStyles
        }}
        onFocus={() => setShrink(true)}
        onBlur={(e) => {
          !e.target.value && setShrink(false);
          handleBlur && handleBlur(e);
        }}
        size={size}
        autoFocus={autoFocus}
        multiline={multiline}
        disabled={disabled}
        required={required}
        minRows={minRows}
        maxRows={maxRows}
        InputLabelProps={{
          shrink: shrink,
          classes: { root: classes.labelTransition },
          ...InputLabelProps
        }}
        inputProps={inputProps}
        InputProps={InputProps}
        helperText={getErrorMessage()}
        value={value}
        onChange={handleChange}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        error={
          fieldArray
            ? error && form?.touched?.[formName]?.[index]?.[fieldName]
            : isObject
            ? form?.touched?.[isObject]?.[fieldName] && form?.errors?.[isObject]?.[fieldName]
            : error && form?.touched[id]
        }
        placeholder={placeholder}
      />
      {helperText && (
        <Typography variant="caption_regular" marginTop="5px" textAlign="left">
          <span style={{ color: getColor('neutral', 600) }}>{helperText}</span>
        </Typography>
      )}
    </div>
  );
};

export function InputField(props: any) {
  return <Field component={CustomTextField} {...props} />;
}
