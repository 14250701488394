import { ActionOptionType } from '../../backers/constants/IndividualBackerConstants';

export enum ActionOptions {
  VIEW_STARTUP_DETAILS = 'View Startup Details',
  SHARE_DEAL_URL = 'Share Deal URL',
  CLOSE_DEAL = 'Close Deal',
  CANCEL_DEAL = 'Cancel Deal'
}
export const ActionOptionsList: ActionOptionType[] = [
  {
    label: ActionOptions.VIEW_STARTUP_DETAILS
  },
  {
    label: ActionOptions.SHARE_DEAL_URL
  },
  {
    label: ActionOptions.CLOSE_DEAL
  },
  {
    label: ActionOptions.CANCEL_DEAL
  }
];

export enum CommunicationLogStatus {
  INVITED = 'invited',
  VIEWED = 'viewed',
  COMMITED = 'commited',
  TRANSFERRED = 'transferred'
}

export enum DealTabType {
  STARTUP_INFO = 'startup_info',
  SCHEME_INFO = 'scheme_info'
}

export const DEAL_FORM_TAB_OPTIONS: any = [
  {
    name: 'STARTUP INFO',
    value: DealTabType.STARTUP_INFO,
    content: ''
  },
  {
    name: 'SCHEME INFO',
    value: DealTabType.SCHEME_INFO,
    content: ''
  }
];
