import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import BackersService from '../service/BackersService';
import { validateArray } from '../../../common/utils/common';
import { uniqueId } from 'lodash';

export default class BackersManager {
  private userId: string;
  private permalink: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
    this.permalink = authContext?.authState?.permalink as string;
  }

  async getAllBackers() {
    try {
      const response: any = await BackersService.getAllBackers(this.userId);
      let data = response?.data;
      if (validateArray(data?.backers)) {
        data.backers = data?.backers?.map((item: any, index: any) => {
          const newItem = { ...item };
          newItem.backer_id = newItem?.id;
          newItem.id = index;
          return newItem;
        });
      }
      if (validateArray(data?.ca_signed)) {
        data.ca_signed = data?.ca_signed?.map((item: any, index: any) => {
          const newItem = { ...item };
          newItem.backer_id = newItem?.id;
          newItem.id = index;
          return newItem;
        });
      }
      if (validateArray(data?.ca_sent)) {
        data.ca_sent = data?.ca_sent?.map((item: any, index: any) => {
          const newItem = { ...item };
          newItem.backer_id = newItem?.id;
          newItem.id = index;
          return newItem;
        });
      }
      if (validateArray(data?.invited)) {
        data.invited = data?.invited?.map((item: any, index: any) => {
          const newItem = { ...item };
          newItem.backer_id = newItem?.id;
          newItem.id = index;
          return newItem;
        });
      }
      if (validateArray(data?.profile_created)) {
        data.profile_created = data?.profile_created?.map((item: any, index: any) => {
          const newItem = { ...item };
          newItem.backer_id = newItem?.id;
          newItem.id = index;
          return newItem;
        });
      }

      return data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getBackerDetail(backerId?: string) {
    try {
      const response: any = await BackersService.getBackerDetail(this.userId, backerId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async inviteMember(data: any) {
    try {
      const formData = { ...data, type_of_investment_profile: data?.type_of_investment_profile?.value };
      const response: any = await BackersService.inviteMemeber(this.userId, 'backer', 'invite', formData);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async reInviteMember(
    backer_array: any,
    inv_id: string,
    mode_of_comm: string,
    type_of_comm: string,
    type_of_investment_profile: string
  ) {
    try {
      const formData = {
        backer_array,
        investorCommitmentId: '',
        resend_drawdown_notice: '',
        startup_id: '',
        inv_id,
        mode_of_comm,
        type_of_comm,
        type_of_investment_profile
      };
      const response: any = await BackersService.reInviteMember(this.permalink, formData);
      return response?.data?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async bulkInviteMember(data: any) {
    try {
      const formData = new FormData();
      if (data?.file) formData.append('file', data?.file);
      const response: any = await BackersService.bulkInviteMember(this.userId, formData);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async checkCommunicationRecord(inv_id: any, type_of_comm: string) {
    try {
      const response: any = await BackersService.checkCommunicationRecord(this.permalink, inv_id, type_of_comm);
      return response?.data?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
