export const breadcrumbData = {
  breadcrumbSeparator: false,
  stepKey: 1,
  steps: [
    {
      title: 'DASHBOARD - BACKERS',
      titleDisplay: true
    }
  ],
  pageHeader: {
    header: 'Backers',
    navigationButton: false,
    helpText: 'Backers'
  }
};

export enum BACKER_ONBOARDING_STATUS {
  ALL = 'All',
  CA_SENT = 'CA Sent',
  CA_ESIGNED = 'CA e-Signed',
  INVITED = 'Invited',
  PROFILE_CREATED = 'Profile Created'
}

export const BACKER_ONBOARDING_STATUS_KEY = {
  [BACKER_ONBOARDING_STATUS.ALL]: 'backers',
  [BACKER_ONBOARDING_STATUS.CA_ESIGNED]: 'ca_signed',
  [BACKER_ONBOARDING_STATUS.CA_SENT]: 'ca_sent',
  [BACKER_ONBOARDING_STATUS.INVITED]: 'invited',
  [BACKER_ONBOARDING_STATUS.PROFILE_CREATED]: 'profile_created'
};

export const TYPE_OF_COMMUNICATION_KEY: any = {
  [BACKER_ONBOARDING_STATUS.CA_SENT]: 'no_ca_sign',
  [BACKER_ONBOARDING_STATUS.INVITED]: 'no_profile'
};

export type ColumnObjectType = {
  field: string;
  headerAlign?: string;
  align?: string;
  flex?: number;
  minWidth?: number;
  headerName: string;
  isSearchable?: boolean;
  renderCell?: Function;
  type?: string;
};

export const backersColumnsData: ColumnObjectType[] = [
  {
    field: 'name',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 200,
    headerName: 'NAME',
    isSearchable: true
  },

  {
    field: 'email',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 350,
    headerName: 'EMAIL',
    isSearchable: true
  },
  {
    field: 'mobile',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 200,
    headerName: 'MOBILE'
  },
  {
    field: 'profile_status',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 200,
    headerName: 'ONBOARDING STATUS'
  },
  {
    field: 'formated_joined_date',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 170,
    headerName: 'INVITED TO SYNDICATE',
    type: 'date'
  },
  {
    field: 'onboarded_to_aif',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 150,
    headerName: 'ONBOARDED TO AIF',
    type: 'date'
  },
  {
    field: 'total_amount_transferred',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 200,
    type: 'number',
    headerName: 'TOTAL TRANSFERRED'
  },
  {
    field: 'deals_participated',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 100,
    type: 'number',
    headerName: '#DEALS'
  },
  {
    field: 'unallocated_amount',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    minWidth: 150,
    headerName: 'Unallocated Funds'
  },
  {
    field: 'actions',
    minWidth: 250,
    headerName: 'ACTIONS'
  }
];

export enum InvestmentProfileType {
  INDIVIDUAL = 'individual',
  PARTNERSHIP = 'partnership',
  JOINT_ACCOUNT = 'joint_account'
}

export const InvestmentProfileTypeOptions = [
  { label: 'Select', value: '' },
  { label: 'Individual/Corporate', value: InvestmentProfileType.INDIVIDUAL },
  { label: 'Partnership', value: InvestmentProfileType.PARTNERSHIP },
  { label: 'Joint Account', value: InvestmentProfileType.JOINT_ACCOUNT }
];
