import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { CoreDropdown } from '../../../ui-library/components/dropdowns/core-dropdown';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';

const ManageCommitModal: FC<any> = ({ openModal, handleClose }) => {
  const initialValues = { name: '', email: '', configure: null, note: '' };

  return (
    <CommonModal submitText="continue" isOpen={!!openModal} handleClose={handleClose} title="Manage Commit" hideButtons>
      <FormContainer>
        <Formik initialValues={initialValues} onSubmit={(values: any) => console.log(values)}>
          {(form) => (
            <Stack gap={2}>
              <InputField disabled fieldName="name" id="name" label="Investor Name" />
              <InputField disabled fieldName="email" id="email" label="Email" type="email" />
              <CoreDropdown
                label="How would you like to configure Commit from this investor?"
                fieldName="configure"
                id="configure"
                options={[]}
              />

              <InputField fieldName="amount" id="amount" type="number" label="Enter the Amount" />

              <br />
              <Stack direction="row" gap={1} justifyContent="end">
                <OutlinedButton onClick={form.handleSubmit}>Cancel</OutlinedButton>
                <ActionButton>Save</ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default ManageCommitModal;
