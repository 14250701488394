import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import SettingService from '../service/SettingService';
import BackersService from '../../backers/service/BackersService';

export default class SettingManager {
  private userId: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
  }

  async fetchSyndicateAdmins() {
    try {
      const response: any = await SettingService.fetchSyndicateAdmins(this.userId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async inviteAdminMember(data: any) {
    try {
      const response: any = await BackersService.inviteMemeber(this.userId, 'admin', 'invite', data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
