import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import SettingCard from './SettingCard';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { useNavigate } from 'react-router-dom';
import { breadcrumbData } from '../constants/SettingConstant';
import { toast } from 'react-toastify';
import SettingManager from '../manager/SettingManager';
import { AuthContext } from '../../../contexts/authContext';
import { validateArray } from '../../../common/utils/common';
import AddAdmin from '../modals/AddAdmin';

const Settings = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const settingManager = new SettingManager(authContext);

  const [adminList, setAdminList] = useState([]);
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);

  const getAdminData = async () => {
    try {
      const response = await settingManager.fetchSyndicateAdmins();
      if (validateArray(response?.result)) {
        setAdminList(response?.result);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAdminData();
  }, []);

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 250,
      headerName: 'NAME'
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 300,
      headerName: 'EMAIL'
    },
    {
      field: 'mobile',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 150,
      headerName: 'MOBILE'
    },
    {
      field: 'onboarding_status',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 200,
      headerName: 'STATUS'
    },
    {
      field: 'active_from',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'ADMIN SINCE',
      type: 'date'
    },
    {
      field: 'actions',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerName: 'ACTIONS',
      renderCell: (params: any) => <LinkButton title="delete" onClick={() => {}} />
    }
  ];

  const navigateToEditProfile = () => navigate('/settings/edit-profile');
  const closeModal = () => setOpenAddAdminModal(false);
  const openModal = () => setOpenAddAdminModal(true);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <ActionButton onClick={navigateToEditProfile}>Edit Profile</ActionButton>
      </Stack>
      <SettingCard />

      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <SectionHeader>Admins</SectionHeader>

        <ActionButton onClick={openModal}>Add Admin</ActionButton>
        <AddAdmin openModal={openAddAdminModal} handleClose={closeModal} />
      </Stack>
      <LvComplexTable rows={adminList} columns={columnsData} rowHeight={48} headerHeight={50} />
    </Box>
  );
};

export default Settings;
