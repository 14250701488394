import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ManageCommitModal from '../modals/ManageCommitModal';
import { Formik } from 'formik';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';

const InviteBackerThisDeal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [showManageCommitModal, setSMCM] = useState(false);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: id,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Invite Backers to this Deal',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      isSearchable: true,
      renderCell: (params: any) => <Link to={`/backers/${params?.row?.name}`}>{params?.row?.name}</Link>
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'EMAIL'
    },
    {
      field: 'manage_commit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'MANAGE COMMIT'
    },
    {
      field: 'onboarding_status',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'ONBOARDING STATUS'
    },
    {
      field: 'total_transferred',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'TOTAL TRANSFERRED'
    },
    {
      field: 'deals',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: '#DEALS'
    },
    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      renderCell: (params: any) => {
        return (
          <LinkButton
            onClick={() => {
              setSMCM(params.row);
            }}
            title="manage commit"
          />
        );
      }
    }
  ];

  const rowData = [
    {
      id: 1,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    },
    {
      id: 2,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    },
    {
      id: 3,
      logo: '',
      name: 'Backer 1',
      email: 'active_backer@gmail.com'
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <ManageCommitModal
        openModal={showManageCommitModal}
        handleClose={() => {
          setSMCM(false);
        }}
      />
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
      </Stack>

      <LvComplexTable
        rows={rowData}
        columns={columnsData}
        leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
        activeFilters={filters}
        // filterMode="server"
        checkboxSelection
        onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        // enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />

      <Box className="text-left" mt={2}>
        <Formik initialValues={{ medium: null }} onSubmit={(values: any) => console.log(values)}>
          {(form) => (
            <Stack gap={2} pl={0.5}>
              <RadioButtonSelect
                fieldName="medium"
                id="medium"
                formLabel="Invite Backers to this Deal via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email' },
                  { name: 'Whatsapp', value: 'whatsapp' }
                ]}
              />

              <Stack direction="row" gap={1}>
                <ActionButton onClick={form.handleSubmit}>Send Test Email/Whatsapp</ActionButton>
                <ActionButton>Send Invite Email/WhatsApp</ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default InviteBackerThisDeal;
