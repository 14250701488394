import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Field } from 'formik';
import { dropdownStyle, primaryStyle, noOutlineDropdownStyle } from './core-dropdown-styles';
import { getColor } from '../../colors';
import { Chip } from '@mui/material';

export type CoreDropdownProps = {
  /**
   * onClick function when you select a option
   */
  onClickMenuItem?: Function;

  /**
   * Options array.  Ex: [
        { label: 'Funding Round', value: 'funding_round' },
        { label: 'Shareholder', value: 'shareholder' }
      ],
   */
  options: object[];

  /**
   * Dropdown placeholder and lable. Ex: "Remind Me Later"
   */
  label?: String;

  /**
   *here we can pass mui InputField Props for textfield component
   */
  InputProps?: any;

  /**Default Value of dropdown of type {label: '', value:''} */
  defaultValue?: object;

  /** a string for unique name for label for identification */
  dropdownType?: string;

  /** a boolean value if true adds primary gray dropdown styles */
  primary?: boolean;

  /** a boolean value if true then styles accoring to the no outlined label will get implemented */
  noOutline?: boolean;

  /**a boolean flag for error prop */
  error?: boolean;

  /**a string to show helper text or error message */
  errorMessage?: string;

  handleChange?: Function;

  /**unique identifier for input field */
  id?: string;

  /**unique name for input field */
  name?: string;

  /** prop for field if it is required */
  required?: boolean;

  /**implicit prop from formik. Dont have to pass as prop */
  form?: any;

  value?: any;

  disabled?: boolean;

  index?: number;

  fieldArray?: boolean;

  formName?: string;

  fieldName?: string;
  InputLabelProps?: any;
  placeholder?: any;
  clearable?: boolean;
  multiple?: boolean;
};

export function CoreDropdown({
  onClickMenuItem,
  options,
  label = 'Label',
  defaultValue = { label: '', value: '' },
  dropdownType,
  primary,
  noOutline,
  error,
  errorMessage,
  InputProps = '',
  handleChange,
  name = '',
  value,
  id = '',
  form,
  disabled = false,
  fieldArray = false,
  fieldName = '',
  index = 0,
  InputLabelProps,
  placeholder,
  formName = '',
  clearable = false,
  required = false,
  multiple
}: CoreDropdownProps) {
  const [dropdownValue, setDropdownValue] = useState<any>(defaultValue);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (option: { label: string; value: string }, label: any) => {
    setDropdownValue(option);
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      if (multiple) {
        const prevVal = form?.values[fieldName] ?? [];
        let newVal = [...prevVal];
        const exist = newVal?.find((item: any) => item?.value === option?.value);
        if (exist) {
          newVal = newVal?.filter((item: any) => item?.value !== exist?.value);
        } else newVal.push(option);

        form?.setFieldValue(id, newVal);
      } else {
        form?.setFieldValue(id, option);
      }
    }
    onClickMenuItem && onClickMenuItem(option, label);
    handleChange && handleChange(option);
    !multiple && setAnchorEl(false);
  };

  const getErrorMessage = () => {
    let errorMessage = '';
    if (form) {
      if (fieldArray && error && form?.touched?.[formName]?.[index]?.[fieldName]) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        if (typeof form?.errors?.[id] === 'object') errorMessage = form?.errors?.[id]?.value;
        else errorMessage = form?.errors?.[id];
      }
    }
    return errorMessage;
  };

  console.log(
    options.filter((option: any) => option['value'] === (fieldName ? form?.values[fieldName]?.value : value?.value))
  );

  return (
    <div>
      <Autocomplete
        multiple={multiple}
        disablePortal
        id="combo-box-demo"
        options={options}
        value={
          multiple
            ? options.filter((option: any) =>
                fieldName
                  ? form?.values[fieldName]?.map((item: any) => item?.value)?.includes(option?.value)
                  : value?.value?.map((item: any) => item?.value)?.includes(option?.value)
              ) ?? defaultValue
            : options.find(
                (option: any) => option['value'] === (fieldName ? form?.values[fieldName]?.value : value?.value)
              ) ?? defaultValue
        }
        open={anchorEl}
        disabled={disabled}
        onClose={() => setAnchorEl(false)}
        onOpen={() => setAnchorEl(true)}
        sx={primary ? primaryStyle : noOutline ? noOutlineDropdownStyle : dropdownStyle}
        fullWidth
        disableClearable={!clearable}
        popupIcon={<img src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg" alt="arrow-down" />}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            required={required}
            id={id}
            name={name}
            InputProps={{ ...params.InputProps, ...InputProps }}
            error={fieldArray ? error && form?.touched?.[formName]?.[index]?.[fieldName] : error && form?.touched[id]}
            helperText={getErrorMessage()}
            InputLabelProps={{ ...params.InputLabelProps, ...InputLabelProps }}
          />
        )}
        renderOption={(props, option: any) =>
          option?.value !== '' &&
          option !== '' && ( // to avoid MUI error, validation is handled by formik
            <Box
              style={{
                height: '49px',
                borderBottom: options[options.length - 1] === option ? '' : `1px solid ${getColor('border', 400)}`
              }}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
              onClick={() => handleClick(option, dropdownType)}
            >
              <span style={{ fontFamily: 'Work Sans', fontWeight: 400, fontSize: '14px', lineHeight: '21px' }}>
                {option?.label}
              </span>
            </Box>
          )
        }
        renderTags={(value: readonly string[], getTagProps) => {
          return value.map((option: any, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant="outlined"
                label={option?.label}
                key={key}
                {...tagProps}
                onDelete={() => {
                  const prevVal = form?.values[fieldName] ?? [];
                  let newVal = [...prevVal];
                  newVal = newVal?.filter((item: any) => item?.value !== option?.value);
                  form?.setFieldValue(id, newVal);
                }}
              />
            );
          });
        }}
        onChange={(event, value, reason) => {
          handleChange && handleChange(value);
          if (reason == 'clear') {
            setDropdownValue(value);
          }
        }}
      />
    </div>
  );
}

export function Dropdown(props: any) {
  return (
    <>
      <Field component={CoreDropdown} {...props} />
    </>
  );
}
