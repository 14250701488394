import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { Stack } from '@mui/material';
import { CoreDropdown } from '../../../ui-library/components/dropdowns/core-dropdown';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';

import { useStyles } from './styles';
import { FileUpload } from '../../../ui-library/components/form-elements/file-upload/FileUpload';

const BulkInviteBackerModal: FC<any> = ({ openModal, handleClose, reInvite, selectedRows, handleSubmit }) => {
  const classes = useStyles();
  const initialValues = { file: undefined, medium: null };
  return (
    <CommonModal
      submitText="continue"
      isOpen={openModal}
      handleClose={handleClose}
      title={`Bulk ${reInvite ? 'Re-Invite' : 'Invite'} Backers to Syndicate ${
        reInvite && selectedRows && '(' + selectedRows?.length + ' backers selected)'
      }`}
      hideButtons
    >
      <FormContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(form) => (
            <Stack gap={2}>
              {!reInvite && (
                <FileUpload
                  title="upload excel file (max size 2 MB)"
                  selectedFile={(file: any) => form.setFieldValue('file', file)}
                  value={form?.values?.file}
                  label="Upload excel sheet with backers details"
                  helperText="Note: backers who are already invited will not be sent any email"
                />
              )}
              <RadioButtonSelect
                fieldName="medium"
                id="medium"
                formLabel="Send Invite via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email' },
                  { name: 'Whatsapp', value: 'whatsapp' }
                ]}
              />

              <br />
              <Stack direction="row" gap={1} justifyContent="end">
                <ActionButton onClick={form.handleSubmit}>Send Test Email/Whatsapp</ActionButton>
                <ActionButton>Send Invite Email/WhatsApp</ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default BulkInviteBackerModal;
