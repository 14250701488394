import { Box, Stack } from '@mui/material';
import React from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';

const EditProfile = () => {
  const navigate = useNavigate();
  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - SETTINGS',
        titleDisplay: true
      },
      {
        title: 'Edit Profile',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Edit Profile',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const initialValues = { name: '', adminName: '', adminEmail: '', allowAccess: null, ccEmails: '' };

  return (
    <Box sx={{ overflowX: 'hidden' }} width="60%" textAlign="left">
      <Breadcrumb
        steps={breadcrumbData.steps}
        breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
        pageHeader={breadcrumbData.pageHeader}
        stepKey={breadcrumbData.stepKey}
      />
      <Formik initialValues={initialValues} onSubmit={(values: any) => console.log(values)}>
        {(form) => (
          <Stack gap={2}>
            <InputField fieldName="name" id="name" label="Syndicate Name" />
            <InputField fieldName="adminName" id="adminName" label="Primary Admin Name" t />
            <InputField fieldName="adminEmail" id="adminEmail" label="Primary Admin Email" type="email" />

            <RadioButtonSelect
              fieldName="allowAccess"
              id="allowAccess"
              formLabel="Do you want to allow backers to access public deals on LetsVenture Platform?"
              required
              options={[
                { name: 'Yes', value: 'yes' },
                { name: 'No', value: 'no' }
              ]}
            />
            <InputField
              fieldName="ccEmails"
              id="ccEmails"
              label="cc all emails from LetsVenture platform to these email ids"
              multiline
              minRows={5}
            />
            <br />
            <Stack direction="row" gap={1} justifyContent="end">
              <OutlinedButton>Cancel</OutlinedButton>
              <ActionButton onClick={form.handleSubmit}>Save</ActionButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </Box>
  );
};

export default EditProfile;
