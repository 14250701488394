import React, { FC, useContext, useEffect, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { Alert, Stack } from '@mui/material';
import { Dropdown } from '../../../ui-library/components/dropdowns/core-dropdown';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../manager/BackersManager';
import { toast } from 'react-toastify';
import {
  InvestmentProfileType,
  InvestmentProfileTypeOptions,
  TYPE_OF_COMMUNICATION_KEY
} from '../constants/BackersConstant';
import * as Yup from 'yup';

const InviteBackerModal: FC<any> = ({ openModal, handleClose, reInvite, editBacker, handleSubmit }) => {
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);

  const [letChooseEmail, setLetChooseEmail] = useState(false);
  const [letChooseWhatsapp, setLetChooseWhatsapp] = useState(false);
  const [showWhatsapp, setShowWhatsapp] = useState(true);
  const [showError, setShowError] = useState(false);
  const [lastEmailReminder, setLastEmailReminder] = useState('');
  const [lastWhatsappReminder, setLastWhatsappReminder] = useState('');

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    type_of_investment_profile: InvestmentProfileTypeOptions.find(
      (item: any) => item?.value === InvestmentProfileType.INDIVIDUAL
    ),
    medium: 'email',
    note: ''
  });

  const checkCommunicationRecord = async (inv_id: any, type_of_comm: string) => {
    try {
      const response = await backersManager.checkCommunicationRecord(inv_id, type_of_comm);
      setLetChooseEmail(response?.let_choose_email);
      setLetChooseWhatsapp(response?.let_choose_whatsapp);
      setShowWhatsapp(response?.show_whatsapp);
      setShowError(response?.show_error);
      setLastEmailReminder(response?.last_email_reminder_sent_on);
      setLastWhatsappReminder(response?.last_whatsapp_reminder_sent_on);
      setInitialValues({
        name: openModal?.name ?? '',
        email: openModal?.email ?? '',
        type_of_investment_profile: openModal?.type_of_investment_profile
          ? { label: '', value: '' }
          : InvestmentProfileTypeOptions.find((item: any) => item?.value === InvestmentProfileType.INDIVIDUAL),
        medium: response?.let_choose_email ? 'email' : response?.let_choose_whatsapp ? 'whatsapp' : '',
        note: ''
      });
    } catch (e: any) {
      toast.error(e);
    }
  };

  useEffect(() => {
    if (openModal && reInvite) {
      checkCommunicationRecord(openModal?.inv_id, TYPE_OF_COMMUNICATION_KEY[openModal?.profile_status]);
    }
  }, [openModal]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required')
  });

  return (
    <CommonModal
      submitText="continue"
      isOpen={openModal}
      handleClose={handleClose}
      title={
        editBacker ? 'Edit Backer Details' : reInvite ? 'Re-Invite Backer to Syndicate' : 'Invite Backer to Syndicate'
      }
      hideButtons
    >
      <FormContainer>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <Stack gap={2}>
              <InputField
                disabled={reInvite}
                value={form?.values?.name}
                fieldName="name"
                id="name"
                name="name"
                label="Name"
                required
                error={!!form.errors.name}
              />
              <InputField
                value={form?.values?.email}
                fieldName="email"
                id="email"
                label="Email"
                type="email"
                required
                error={!!form.errors.email}
                disabled={reInvite}
              />

              <Dropdown
                value={form?.values?.type_of_investment_profile}
                fieldName="type_of_investment_profile"
                id="type_of_investment_profile"
                label="Type of investor"
                options={InvestmentProfileTypeOptions}
                disabled={reInvite}
              />

              <RadioButtonSelect
                value={form?.values?.medium}
                fieldName="medium"
                id="medium"
                formLabel="Send Invite via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email', disabled: !letChooseEmail },
                  { name: 'Whatsapp', value: 'whatsapp', disabled: !letChooseWhatsapp, hide: !showWhatsapp }
                ]}
              />

              {((!letChooseEmail && showError) || (!letChooseWhatsapp && showError)) && (
                <Alert severity="warning">{showError}</Alert>
              )}

              <InputField
                value={form?.values?.note}
                fieldName="note"
                id="note"
                label="Add a personalised note to the invite email/whatsapp"
                multiline
                minRows={5}
              />

              <br />
              <Stack direction="row" gap={1} justifyContent="end">
                <ActionButton>Send Test Email/Whatsapp</ActionButton>
                <ActionButton onClick={form.handleSubmit} disabled={!form?.values?.medium}>
                  Send Invite Email/WhatsApp
                </ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default InviteBackerModal;
